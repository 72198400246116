// 输出显示
// numBelong 号码归属 联通
// warehouseId   号码仓库
// storeStatus 在库状态
export class Show {
  // constructor(provcode,citycode,compRule,numBelong,storestatus){
  // serviceKind   8

  static serviceKinds = [
    {
      value: 8,
      label: '联通'
    },
    {
      value: 9,
      label: '电信'
    },
    {
      value: 7,
      label: '移动'
    },
  ]

  // 计费要素
   static  callType =  [
    {
      value: 10,
      label: '语音主叫'
    },
    {
      value: 11,
      label: '语音被叫'
    },
    {
      value: 12,
      label: '语音互转'
    },
    {
      value: 20,
      label: '短信上行'
    },
    {
      value: 21,
      label: '短信下行'
    },
    {
      value: 30,
      label: '彩信上行'
    },
    {
      value: 31,
      label: '彩信下行'
    }
  ]
  // 话单类型
  static  detailbillType =  [
    {
      value: 2001,
      label: '语音'
    },
    {
      value: 2003,
      label: '流量'
    },
    {
      value: 2002,
      label: '短信'
    }
  ]
  //  租费周期
  static priceCycle = [
    {
      value: 'D',
      label: '日'
    },
    {
      value: 'W',
      label: '周'
    },
    {
      value: 'M',
      label: '月'
    },
    {
      value: 'Y',
      label: '年'
    }
  ]
  //  流量资费周期
  static gprsCycle = [
    {
      value: 'D',
      label: '日(普通日包)'
    },
    {
      value: 'W',
      label: '周'
    },
    {
      value: 'M',
      label: '月'
    },
    {
      value: 'Y',
      label: '年'
    },
    {
      value: 'AUTOD',
      label: '自动续订日包'
    },
    {
      value: 'AUTOM',
      label: '自动续订月包'
    }
  ]
  // 产品优先级
  static  prodPriority = [
    {value: 1, label: '1级'},
    {value: 2, label: '2级'},
    {value: 3, label: '3级'},
    {value: 4, label: '4级'},
    {value: 5, label: '5级'},
    {value: 6, label: '6级'},
    {value: 7, label: '7级'},
    {value: 8, label: '8级'},
    {value: 9, label: '9级'},
    {value: 10, label: '10级'}
  ]
  // 场景资费
  static sceneUnitRules = [
    {
      value: 0,
      label: '分钟'
    },
    {
      value: 1,
      label: 'KB'
    },
    {
      value: 2,
      label: '条'
    },
    {
      value: 3,
      label: '条'
    }
  ]
  static sceneFlagRules = [
    {
      value: 0,
      label: '不可消耗'
    },
    {
      value: 1,
      label: '可消耗'
    }
  ]
  static sceneBillFlagRules = [
    {
      value: 0,
      label: '转售用户计费'
    },
    {
      value: 1,
      label: '运营商结算计费'
    }
  ]
  static compRules = [
    {
      value: '!=v',
      label: '不等于固定值'
    }, {
      value: '=v',
      label: '等于固定值'
    }, {
      value: '=v+d*n',
      label: '等差'
    }, {
      value: '!pos',
      label: '不等于自身其他位置'
    }, {
      value: 'pos',
      label: '等于自身其他位置'
    }, {
      value: 'inc',
      label: '包含'
    }, {
      value: '!nc',
      label: '不包含'
    }
  ]
  static numBelongs = [
    {
      value: 'CUC',
      label: '联通'
    },
    {
      value: 'CTC',
      label: '电信'
    }, {
      value: 'CMC',
      label: '移动'
    }
  ]
  static warehouseIds = [
    {
      value: 'crm',
      label: 'CRM'
    },
    {
      value: 'wap',
      label: '网厅'
    },
    {
      value: 'weixin',
      label: '微信端'
    },
    {
      value: 'agent',
      label: '代理商'
    }
  ]
  static storeStatus = [
    {
      value: 0,
      label: '可使用'
    },
    {
      value: 1,
      label: '已预占'
    },
    {
      value: 2,
      label: '已使用'
    },
    {
      value: 3,
      label: '待回收'
    },
    {
      value: 4,
      label: '已预留'
    },
    {
      value: 5,
      label: '待激活'
    }

  ]
  static numberLevels = [
    {"value": 1, "label": "1级"}, {"value": 2, "label": "2级"}, {"value": 3, "label": "3级"}, {
      "value": 4,
      "label": "4级"
    }, {"value": 5, "label": "5级"}, {"value": 6, "label": "6级"}, {"value": 7, "label": "7级"}, {
      "value": 8,
      "label": "8级"
    }, {"value": 9, "label": "9级"}, {"value": 10, "label": "10级"}
  ]
  static numTypes = [
    {label: 'AAAAAA', value: 'AAAAAA'},
    {label: 'AAAAA', value: 'AAAAA'},
    {label: 'AAAA', value: 'AAAA'},
    {label: 'AAA', value: 'AAA'},
    {label: 'ABCDABCD', value: 'ABCDABCD'},
    {label: 'ABCDE', value: 'ABCDE'},
    {label: 'EDCBA', value: 'EDCBA'},
    {label: 'ABCDDCBA', value: 'ABCDDCBA'},
    {label: 'AABB', value: 'AABB'},
    {label: 'ABAB', value: 'ABAB'},
    {label: 'ABABAB', value: 'ABABAB'},
    {label: 'ABCD', value: 'ABCD'},
    {label: 'DCBA', value: 'DCBA'},
    {label: 'ABCABC', value: 'ABCABC'},
    {label: 'ABCCBA', value: 'ABCCBA'},
    {label: 'AAAAAB', value: 'AAAAAB'},
    {label: 'AAAAB', value: 'AAAAB'},
    {label: 'AAAB', value: 'AAAB'},
  ]
  // 套餐类型
  static prodTypes = [
    {
      value: 0,
      label: '电信模组套餐'
    },
    {
      value: 1,
      label: '联通资源池套餐'
    },
    {
      value: 2,
      label: '联通全国模组套餐'
    },
    {
      value: 3,
      label: '联通迷你模组套餐'
    },
    {
      value: 101,
      label: '电信语音包'
    },
    {
      value: 102,
      label: '电信流量包'
    },
    {
      value: 103,
      label: '电信短信包'
    },
    {
      value: 104,
      label: '电信彩信包'
    },
    {
      value: 117,
      label: '电信来显'
    },
    {
      value: 105,
      label: '联通模组语音包'
    },
    {
      value: 106,
      label: '联通模组流量包'
    },
    {
      value: 107,
      label: '联通模组短信包'
    },
    {
      value: 108,
      label: '联通模组彩信包'
    },
    {
      value: 119,
      label: '联通模组来显'
    },
    {
      value: 109,
      label: '联通mini模组语音包'
    },
    {
      value: 110,
      label: '联通mini模组流量包'
    },
    {
      value: 111,
      label: '联通mini模组短信包'
    },
    {
      value: 112,
      label: '联通mini模组彩信包'
    },
    {
      value: 120,
      label: '联通mini模组来显'
    },
    {
      value: 113,
      label: '联通资源池语音包'
    },
    {
      value: 114,
      label: '联通资源池流量包'
    },
    {
      value: 115,
      label: '联通资源池短信包'
    },
    {
      value: 116,
      label: '联通资源池彩信包'
    },
    {
      value: 118,
      label: '联通资源池来显'
    },


  ]
  static eopProdTypes = [
    {
      value: 0,
      label: '0元主套餐'
    },
    {
      value: 1,
      label: '大流量套餐'
    },
    {
      value: 2,
      label: '日租卡套餐'
    },
    {
      value: 3,
      label: '语音包'
    },
    {
      value: 4,
      label: '流量包'
    },
    {
      value: 5,
      label: '短信包'
    }
  ]
  static vopProdTypes = [
    {
      value: 1001,
      label: '基础-国内语音包'
    },
    {
      value: 1002,
      label: '基础-国内数据包'
    },
    {
      value: 1003,
      label: '基础-组合包'
    },
    {
      value: 1004,
      label: '基础-国内短/彩信包'
    },
    {
      value: 2001,
      label: '特服-国际长途包'
    },
    {
      value: 2002,
      label: '特服-国际漫游包'
    },
    {
      value: 2003,
      label: '特服-呼叫转移包'
    },
    {
      value: 3001,
      label: '附加-普通日包'
    },
    {
      value: 3002,
      label: '附加-自动续订日包'
    },
    {
      value: 3003,
      label: '附加-0元1M日包'
    },
    {
      value: 3004,
      label: '附加-数据月包'
    },
    {
      value: 3005,
      label: '附加-数据季包'
    },
    {
      value: 3006,
      label: '附加-数据半年包'
    },
    {
      value: 4001,
      label: '增值-来电显示'
    },
    {
      value: 4002,
      label: '增值-漏电提醒包'
    },
    {
      value: 4003,
      label: '增值-漏电提醒升级版'
    },
    {
      value: 4004,
      label: '增值-语音VPN包'
    }
  ]
  static effectTypes = [
    {
      value: 1,
      label: '立即生效'
    },
    {
      value: 0,
      label: '次月生效'
    }
  ]
  static resTypes = [
    {
      value: 1,
      label: '按天折算'
    },
    {
      value: 2,
      label: '全量'
    }
  ]
  static billresTypes = [
    {
      value: 0,
      label: '语音'
    },
    {
      value: 1,
      label: '数据'
    },
    {
      value: 2,
      label: '短信'
    },
  ]
  static simTypes = [
    {
      value: 4,
      label: '4G'
    },
    {
      value: 3,
      label: '3G'
    },
    {
      value: 5,
      label: '5G'
    }
  ]
  static prodCycles = [
    {
      value: 'D',
      label: '天'
    },
    {
      value: 'W',
      label: '周'
    },
    {
      value: 'M',
      label: '月'
    },
    {
      value: 'Y',
      label: '年'
    }
  ]
  static userStatus = [
    {
      value: 0,
      label: '正常在用',
      remark: 'NORMAL_USE'
    },
    {
      value: 1,
      label: '欠费单停',
      remark: 'OWE_FEE_STOP'
    },
    {
      value: 2,
      label: '欠费双停',
      remark: 'OWE_FEE_ALL_STOP'
    }, {
      value: 3,
      label: '挂失停机',
      remark: 'LOSS_STOP'
    }, {
      value: 4,
      label: '停机保号',
      remark: 'PROTECT_STOP'
    },
    {
      value: 5,
      label: '强制停机',
      remark: 'FORCE_STOP'
    },
    {
      value: 6,
      label: '过户退网',
      remark: 'TRANSFER_OFF'
    },
    {
      value: 7,
      label: '销户退网',
      remark: 'USER_OFF'
    },
  ]
  static orderType = [
    {
      value: 'OPEN_ACCOUNT',
      label: '开户'
    },
    {
      value: 'YHKH',
      label: '开户'
    },
    {
      value: 'CHANGE_FUNCTIONS',
      label: '功能变更'
    },
    {
      value: 'CHANGE_PRODUCT',
      label: '模组变更'
    },
    {
      value: 'TRANSFER_USER',
      label: '过户'
    },
    {
      value: 'CHANGE_CARD',
      label: '补换卡'
    },
    {
      value: 'PROTECT_STOP',
      label: '停机保号'
    },
    {
      value: 'CREDIT_RECOVER',
      label: '信控复机'
    },
    {
      value: 'PROTECT_RECOVER',
      label: '保号复机'
    },
    {
      value: 'LOSS_STOP',
      label: '挂失停机'
    },
    {
      value: 'LOSS_RECOVER',
      label: '解挂复机'
    },
    {
      value: 'CREDIT_STOP_VOICE',
      label: '信控单停'
    },
    {
      value: 'CREDIT_STOP_ALL',
      label: '信控双停'
    },
    {
      value: 'PRE_OPEN_ACCOUNT',
      label: '预开户'
    },
    {
      value: 'CHANGE_MEAL',
      label: '套餐变更'
    },
    {
      value: 'USER_OFF',
      label: '销户'
    },

  ]
  static billTypes = [
    {
      value: 'YYGN001',
      label: '语音国内通话费'
    }, {
      value: 'ZDXF001',
      label: '号码低消'
    },
    {
      value: 'YYGJ002',
      label: '语音国际通话费'
    },
    {
      value: 'YYHZ003',
      label: '语音呼转通话费'
    },
    {
      value: 'SJGN001',
      label: '数据国内通话费'
    },
    {
      value: 'SJGJ002',
      label: '数据国际通话费'
    },
    {
      value: 'DXGN001',
      label: '短信国内通话费'
    },
    {
      value: 'DXGJ002',
      label: '短信国际通话费'
    },
    {
      value: 'CXGN001',
      label: '彩信国内通话费'
    },
    {
      value: 'CXGJ002',
      label: '彩信国际通话费'
    },
    {
      value: 'TCYZ001',
      label: '套餐租费'
    },
    {
      value: 'KXBF001',
      label: '可选包租费'
    }
  ]
  static getbillTypes(arg) {
    let items = Show.billTypes
    for (let item  of  items) {
      if (arg == item.value) {
        return item.label
      }
    }

  }
  static  operAcct = [
    {
      value: 'admin',
      label: '管理员'
    },
    {
      value: 'kefu',
      label: '客服'
    }
  ]
  static riceclub = [
    {
      prodCode: "PD1594884500071",
      prodName: "稻米18套餐",
      sale:70.0,
      club:'',
      donate:'赠送3个月月租费',
    },
    {
      prodCode: "PD1595209846453",
      prodName: "稻米158套餐",
      sale:350.0,
      club:'赠送优酷VIP会员，网易云音乐黑胶会员',
      donate:'赠送2个月月租费',
    },
    {
      prodCode: "PD1595209446133",
      prodName: "稻米88套餐",
      sale:188.0,
      club:'赠送优酷VIP会员，网易云音乐黑胶会员',
      donate:'赠送2个月月租费',
    },
  ]

  static getRiceclub(prodCode) {
    let items = Show.riceclub
    for (let item  of  items) {
      if (prodCode == item.prodCode) {
        return item
      }
    }

  }

   static  identityTypes = [
     {
       value: '01',
       label: '身份证'
     },
     {
       value: '02',
       label: '护照'
     },
     {
       value: '05',
       label: '军官证'
     },
     {
       value: '06',
       label: '营业执照'
     },
     {
       value: '08',
       label: '武警身份证'
     },
     {
       value: '09',
       label: '户口本'
     },
     {
       value: '10',
       label: '港澳居民来往内地通行证'
     },
     {
       value: '11',
       label: '台湾居民来往大陆通行证'
     },
     {
       value: '16',
       label: '介绍信'
     }
   ]
  static getidentityTypes(arg) {
    let items = Show.identityTypes
    for (let item  of  items) {
      if (arg == item.value) {
        return item.label
      }
    }

  }
  // 号码归属运营商
  static getnumBelongs(arg) {
    let items = Show.numBelongs
    for (let item  of  items) {
      if (arg == item.value) {
        return item.label
      }
    }

  }

  // 号码仓库
  static getwarehouseIds(arg) {
    let items = Show.warehouseIds
    for (let item  of  items) {
      if (arg == item.value) {
        return item.label
      }
    }

  }

  static getstoreStatus(arg) {
    let items = Show.storeStatus
    for (let item  of  items) {
      if (arg == item.value) {
        return item.label
      }
    }
  }

  static getnumberLevels(arg) {
    let items = Show.numberLevels
    for (let item  of  items) {
      if (arg == item.value) {
        return item.label
      }
    }

  }

  static getnumTypes(arg) {
    let items = Show.numTypes
    for (let item  of  items) {
      if (arg == item.value) {
        return item.label
      }
    }
  }

  // 套餐
  static getprodTypes(arg) {
    let items = Show.prodTypes
    for (let item  of  items) {
      if (arg == item.value) {
        return item.label
      }
    }
    return Show.prodTypes
  }
// eopprod
  static geteopProdTypes(arg) {
    let items = Show.eopProdTypes
    for (let item  of  items) {
      if (arg == item.value) {
        return item.label
      }
    }
    return Show.prodTypes
  }
  // vop   vopProdTypes
  static getvopProdTypes(arg) {
    let items = Show.vopProdTypes
    for (let item  of  items) {
      if (arg == item.value) {
        return item.label
      }
    }
    return Show.prodTypes
  }


  static geteffectTypes(arg) {
    let items = Show.effectTypes
    for (let item  of  items) {
      if (arg == item.value) {
        return item.label
      }
    }

  }

  static getresTypes(arg) {
    let items = Show.resTypes
    for (let item  of  items) {
      if (arg == item.value) {
        return item.label
      }
    }

  }

  static getprodCycles(arg) {
    let items = Show.prodCycles
    for (let item  of  items) {
      if (arg == item.value) {
        return item.label
      }
    }

  }

  static getsimTypes(arg) {
    let items = Show.simTypes
    for (let item  of  items) {
      if (arg == item.value) {
        return item.label
      }
    }
  }

  static getuserStatus(arg) {
    let items = Show.userStatus
    for (let item  of  items) {
      if (arg == item.value) {
        return item.label
      }
    }

  }

  static getorderType(arg) {
    let items = Show.orderType
    for (let item  of  items) {
      if (arg == item.value) {
        return item.label
      }
    }
  }

  static getoperAcct(arg) {
    let items = Show.operAcct
    for (let item  of  items) {
      if (arg == item.value) {
        return item.label
      }
    }
  }

  static getsceneUnitRules(arg) {
    let items = Show.sceneUnitRules
    for (let item  of  items) {
      if (arg == item.value) {
        return item.label
      }
    }
  }

  static getsceneFlagRules(arg) {
    let items = Show.sceneFlagRules
    for (let item  of  items) {
      if (arg == item.value) {
        return item.label
      }
    }
  }

  static getsceneBillFlagRules(arg) {
    let items = Show.sceneBillFlagRules
    for (let item  of  items) {
      if (arg == item.value) {
        return item.label
      }
    }
  }
  // 话单类型
  static getdetailbillType(arg) {
    let items = Show.detailbillType
    for (let item  of  items) {
      if (arg == item.value) {
        return item.label
      }
    }
  }
  // 计费要素 callType
  static getcallType(arg) {
    let items = Show.callType
    for (let item  of  items) {
      if (arg == item.value) {
        return item.label
      }
    }
  }
  // priceCycle
  static getpriceCycles(arg) {
    let items = Show.priceCycle
    for (let item  of  items) {
      if (arg == item.value) {
        return item.label
      }
    }
  }

  // prodPriority
  static getprodPrioritys(arg) {
    let items = Show.prodPriority
    for (let item  of  items) {
      if (arg == item.value) {
        return item.label
      }
    }
  }

  // serviceKinds
  static getserviceKinds(arg) {
    let items = Show.serviceKinds
    for (let item  of  items) {
      if (arg == item.value) {
        return item.label
      }
    }
  }
  static toPoint(percent) {
    var str = percent.replace("%", "");
    str = str / 100;
    return str;
  }

  static toPercent(point) {
    var str = Number(point * 100).toFixed(1);
    str += "%";
    return str;
  }

  // 语音资费转换
  static toYuan(arg) {
    return arg / 1000 + '元'
  }

  static toMB(arg) {
    return arg / 1024 + 'M'
  }

  static toYuanUnit(type, arg) {
    let value = ''
    switch (type) {
      case 0:
        value = arg / 1000 + '元/分钟'
        break;
      case 1:
        value = arg / 1024 / 1000 + '元/M'
        break;
      case 2:
        value = arg / 1000 + '元/条'
        break;
      case 3:
        value = arg / 1000 + '元/条'
        break;
    }
    return value

  }

  static toUnit(type, arg) {
    let value = ''
    switch (type) {
      case 0:
        value = arg + '秒'
        break;
      case 1:
        value = arg / 1024 + 'M'
        break;
      case 2:
        value = arg + '条'
        break;
      case 3:
        value = arg + '条'
        break;
    }
    return value
  }

  // 分割时间字符串  str.substring(start,end) 2020 05 05 11 22 40
  static  dateStr(str){
     let   year = str.substring(0,4)
     let   month = str.substring(4,6)
     let   day = str.substring(6,8)
     let   hour = str.substring(8,10)
     let   minute = str.substring(10,12)
     let   second = str.substring(12,14)

    return year+'-'+month+'-'+day+' '+hour+':'+minute+':'+second
  }

}

// PR_0001:全国模组

// 综合查询状态：  0  失效  1  生效    2  预失效


