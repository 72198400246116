// import shop from '../../api/shop'

// initial state
const state = {
  // all: []
  productname: '小太阳' ,// 默认值
  producttile: 'title sun' ,// 默认值
  selectnum:{
    phonenum:'',
    name:'',
    idcard:'',
    contactphone:'',
    belongmno:'CUC',
    belongarea:'',
    prod:'',
    provinceid:'',
    address:'',
    occupyflag:'',
    amount:5000
  }
}

// getters
const getters = {
  getproductname : state => state.productname+'xuxian_name',
  selectnum : state => state.selectnum
  // productname(){  //承载变化的changebleNum的值
  //   return state.productname
  // }

}

// actions
const actions = {
  modifyproduct({commit}, name) { // commit 提交；name即为点击后传递过来的参数，此时是 'A餐馆'
    return commit('modifyproduct', name)
  },
  updateName({commit}, name) { // commit 提交；name即为点击后传递过来的参数，此时是 'A餐馆'
    return commit('updateName', name)
  },
  updateIdcard({commit}, idcard) { // commit 提交；name即为点击后传递过来的参数，此时是 'A餐馆'
    return commit('updateIdcard', idcard)
  },
 updateContact({commit}, contact) { // commit 提交；name即为点击后传递过来的参数，此时是 'A餐馆'
    return commit('updateContact',contact)
  },

}

// mutations
const mutations = {
  modifyproduct:(state, name) => {
    state.productname = name // 把方法传递过来的参数，赋值给state中的resturantName
  },
  updateName:(state, name) => {
    state.selectnum.name = name // 把方法传递过来的参数，赋值给state中的resturantName
  },
  updateIdcard:(state, idcard) => {
    state.selectnum.idcard = idcard // 把方法传递过来的参数，赋值给state中的resturantName
  },
  updateContact:(state, contactphone) => {
    state.selectnum.contactphone = contactphone // 把方法传递过来的参数，赋值给state中的resturantName
  } ,
  updateBelongmno:(state, belongmno) => {
    state.selectnum.belongmno = belongmno // 把方法传递过来的参数，赋值给state中的resturantName
  },
  updatePhonenum:(state, phonenum) => {
    state.selectnum.phonenum = phonenum // 把方法传递过来的参数，赋值给state中的resturantName
  },
  updateProd:(state, prod) => {
    state.selectnum.prod = prod // 把方法传递过来的参数，赋值给state中的resturantName
  },
  updateAddress:(state, address) => {
    state.selectnum.address = address // 把方法传递过来的参数，赋值给state中的resturantName
  },
  updateOccupy:(state, occupyflag) => {
    state.selectnum.occupyflag = occupyflag // 把方法传递过来的参数，赋值给state中的resturantName
  },
  updateBelongarea:(state, belongarea) => {
    state.selectnum.belongarea = belongarea // 把方法传递过来的参数，赋值给state中的resturantName
  },
  updateAmount:(state, amount) => {
    state.selectnum.amount = amount // 把方法传递过来的参数，赋值给state中的resturantName
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
