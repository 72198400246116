import Vue from 'vue';
import Router from 'vue-router';
import {getCookie,setCookie, getUrlParams, oauthbycode,weixinStorage,vuexStorage,oauthBase,openidStorage} from '@/api/login'
import store from "@/store";
const originalPush = Router.prototype.push
Router.prototype.push = function push(location) {
    return originalPush.call(this, location).catch(err => err)
}


import async from "async";
// const  async  =  require('async')
Vue.use(Router);
const Shop = () => import(/* webpackChunkName: "selectnum" */'@/view/shop')
const Selectnum = () => import(/* webpackChunkName: "shop" */'@/view/shop/selectnum.vue')
const Selectprod = () => import(/* webpackChunkName: "shop" */'@/view/shop/selectprod.vue')

const Address = () => import(/* webpackChunkName: "address" */'@/view/shop/address.vue')

const Bill = () => import(/* webpackChunkName: "bill" */'@/view/bill')
const Prodused = () => import(/* webpackChunkName: "prodused" */'@/view/prodused')
const Payhistory = () => import(/* webpackChunkName: "payhistory" */'@/view/payhistory')
const Detail = () => import(/* webpackChunkName: "detail" */'@/view/detail')
const Query = () => import(/* webpackChunkName: "query" */'@/view/query')
const Manage = () => import(/* webpackChunkName: "manage" */'@/view/manage')
const Voice = () => import(/* webpackChunkName: "voice" */'@/view/detail/voice.vue')
const Mess = () => import(/* webpackChunkName: "mess" */'@/view/detail/mess.vue')
const Web = () => import(/* webpackChunkName: "mess" */'@/view/detail/web.vue')
const Remind = () => import(/* webpackChunkName: "remind" */'@/view/remind')
const Home = () => import(/* webpackChunkName: "home" */'@/view/home')
const Fee = () => import(/* webpackChunkName: "fee" */'@/view/fee')
const Login = () => import(/* webpackChunkName: "login" */'@/view/login')
const Code = () => import(/* webpackChunkName: "login" */'@/view/login/code.vue')
const Forget = () => import(/* webpackChunkName: "login" */'@/view/login/forget.vue')
const Reset = () => import(/* webpackChunkName: "login" */'@/view/login/reset.vue')
const User = () => import(/* webpackChunkName: "user" */'@/view/user')
const Cart = () => import(/* webpackChunkName: "cart" */'@/view/cart')
const Goods = () => import(/* webpackChunkName: "goods" */'@/view/goods')
const Temporary = () => import(/* webpackChunkName: "temporary" */'@/view/temporary')
const Baseinfo = () => import(/* webpackChunkName: "temporary" */'@/view/baseinfo')
const Charge = () => import(/* webpackChunkName: "charge" */'@/view/charge/')
const Chargetest = () => import(/* webpackChunkName: "charge" */'@/view/charge/test.vue')
const About = () => import(/* webpackChunkName: "about" */'@/view/login/About')


const Actorder = () => import(/* webpackChunkName: "activate" */'@/view/activate/')
const Actfront = () => import(/* webpackChunkName: "activate" */'@/view/activate/front.vue')
const Actback = () => import(/* webpackChunkName: "activate" */'@/view/activate/back.vue')
const Actvideo = () => import(/* webpackChunkName: "activate" */'@/view/activate/video.vue')
const Actcharge = () => import(/* webpackChunkName: "activate" */'@/view/charge/act')
const Rechargeorder = () => import(/* webpackChunkName: "charge" */'@/view/charge/chargeorder')
// begin——天猫
const Acttmallfront = () => import(/* webpackChunkName: "tmallactivate" */'@/view/activate/tmallfront.vue')
const Acttmallback = () => import(/* webpackChunkName: "tmallactivate" */'@/view/activate/tmallback.vue')
const Acttmallvideo = () => import(/* webpackChunkName: "tmallactivate" */'@/view/activate/tmallvideo.vue')

const actComplete = () => import('@/view/activate/complete.vue')

const ActWebRtc = () => import(/* webpackChunkName: "activate" */'@/view/activate/webRtc.vue')
// end——天猫
// const Actorderwx = () => import(/* webpackChunkName: "activatewx" */'@/view/activatewx/')
// const Actfrontwx = () => import(/* webpackChunkName: "activatewx" */'@/view/activatewx/front.vue')
// const Actbackwx = () => import(/* webpackChunkName: "activatewx" */'@/view/activatewx/back.vue')
// const Actvideowx = () => import(/* webpackChunkName: "activatewx" */'@/view/activatewx/video.vue')
// const Actchargewx = () => import(/* webpackChunkName: "activatewx" */'@/view/charge/actwx')
// const Rechargeorderwx = () => import(/* webpackChunkName: "charge" */'@/view/charge/chargeorder')

//
// const Register = () => import(/* webpackChunkName: "rice" */'@/view/rice/register.vue')
// const Ricecard = () => import(/* webpackChunkName: "rice" */'@/view/rice/card.vue')
// const Riceprod = () => import(/* webpackChunkName: "rice" */'@/view/rice/selectprod.vue')
// const Ricenum = () => import(/* webpackChunkName: "rice" */'@/view/rice/selectnum.vue')
// const Ordercharge = () => import(/* webpackChunkName: "rice" */'@/view/rice/ordercharge.vue')
// const Club = () => import(/* webpackChunkName: "rice" */'@/view/rice/club.vue')
// const Custcharge = () => import(/* webpackChunkName: "rice" */'@/view/rice/custcharge.vue')
//
// const Actricefront = () => import(/* webpackChunkName: "rice" */'@/view/rice/ricefront.vue')
// const Actriceback = () => import(/* webpackChunkName: "rice" */'@/view/rice/riceback.vue')
// const Actricevideo = () => import(/* webpackChunkName: "rice" */'@/view/rice/ricevideo.vue')
  // begin ----  补换卡

const  Cardorder = () => import(/* webpackChunkName: "card" */'@/view/rep/')
const  Cardfront = () => import(/* webpackChunkName: "card" */'@/view/rep/front.vue')
const  Cardfront2 = () => import(/* webpackChunkName: "card" */'@/view/rep/front2.vue')
const  Cardback = () => import(/* webpackChunkName: "card" */'@/view/rep/back.vue')
const  Cardvideo = () => import(/* webpackChunkName: "card" */'@/view/rep/video.vue')
const  Cardcharge = () => import(/* webpackChunkName: "card" */'@/view/rep/custcharge.vue')
// const  Rephargeorder = () => import(/* webpackChunkName: "card" */'@/view/charge/chargeorder')

  // end ----补换卡



// begin -过户

const  Transferorder = () => import(/* webpackChunkName: "transfer" */'@/view/transfer/')
const  Transferfront = () => import(/* webpackChunkName: "transfer" */'@/view/transfer/front.vue')
const  Transferback = () => import(/* webpackChunkName: "transfer" */'@/view/transfer/back.vue')
const  Transfergroupphoto = () => import(/* webpackChunkName: "transfer" */'@/view/transfer/groupphoto.vue')
const  Transfervideo = () => import(/* webpackChunkName: "transfer" */'@/view/transfer/video.vue')
const  Trancharge = () => import(/* webpackChunkName: "transfer" */'@/view/transfer/charge.vue')
// const  Idquerylist = () => import(/* webpackChunkName: "transfer" */'@/view/transfer/list.vue')
// end -过户

// begin -白卡二维码

const  checkCard = () => import(/* webpackChunkName: "checkCard" */'@/view/checkCard/')

// end -白卡二维码

const batchActivate = () => import('@/view/activate/batch')
const foreignerActivate = () => import('@/view/activate/foreigner')

const realnameIndex = () => import('@/view/realname/index')
const realnameIndex2 = () => import('@/view/realname/index2')
const realnameIndex3 = () => import('@/view/realname/index3')
const repMyServiceNums = () => import('@/view/rep/my-service-nums')

const faqIndex = () => import('@/view/faq/index')
const faqProtocol = () => import('@/view/faq/protocol')
const faqProtocol2023 = () => import('@/view/faq/protocol2023')

const userOffIndex = () => import('@/view/userOff/index')

const refundCheck = () => import('@/view/refund/check')

const wishesSms = () => import('@/view/wishes/sms')

const netChannelVerify = () => import('@/view/verify/netChannel')

// begin -代理商实名--xuxy

const  Agtcheck = () => import(/* webpackChunkName: "agent" */'@/view/agent/')
const  Agtfront = () => import(/* webpackChunkName: "agent" */'@/view/agent/front.vue')
const  Agtback = () => import(/* webpackChunkName: "agent" */'@/view/agent/back.vue')
const  Agtvideo = () => import(/* webpackChunkName: "agent" */'@/view/agent/video.vue')
// end -代理商实名--xuxy



const routes = [
    {
        path: '*',
        redirect: '/home'
    },
    // begin-代理商实名--xuxy
    {
        path: '/agtfront',
        name: 'agtfront',
        component: Agtfront,
        meta: {
            title: '身份证正面校验'
        }
    },
    {
        path: '/agtback',
        name: 'agtback',
        component: Agtback,
        meta: {
            title: '身份证反面验证'
        }
    },
    {
        path: '/agtvideo',
        name: 'agtvideo',
        component: Agtvideo,
        meta: {
            title: '视频认证'
        }
    },
    // end-代理商实名--xuxy

    // BEGIN-过户
    {
        path: '/transferorder',
        name: 'transferorder',
        component: Transferorder,
        meta: {
            title: '流量卡激活'
        }
    },
    {
        path: '/transferfront',
        name: 'transferfront',
        component: Transferfront,
        meta: {
            title: '正面验证-流量卡激活'
        }
    },
    {
        path: '/transferback',
        name: 'transferback',
        component: Transferback,
        meta: {
            title: '反面验证-流量卡激活'
        }
    },
    {
        path: '/transfervideo',
        name: 'transfervideo',
        component: Transfervideo,
        meta: {
            title: '视频验证-流量卡激活'
        }
    },
 {
        path: '/trancharge',
        name: 'trancharge',
        component: Trancharge,
        meta: {
            title: '开户激活充值'
        }
    },


    // end-过户
    {
        path: '/cardorder',
        name: 'cardorder',
        component: Cardorder,
        meta: {
            title: '补换卡'
        }
    },

    {
        path: '/cardfront',
        name: 'cardfront',
        component: Cardfront,
        meta: {
            title: '正面校验-补换卡'
        }
    },
    {
        path: '/cardfront2',
        name: 'cardfront2',
        component: Cardfront2,
        meta: {
            title: '正面校验2-补换卡'
        }
    },
 {
        path: '/cardback',
        name: 'cardback',
        component: Cardback,
        meta: {
            title: '反面校验-补换卡'
        }
    },
    {
        path: '/cardvideo',
        name: 'cardvideo',
        component: Cardvideo,
        meta: {
            title: '视频验证-补换卡'
        }
    },
{
        path: '/cardcharge',
        name: 'cardcharge',
        component: Cardcharge,
        meta: {
            title: '邮寄地址'
        }
    },
    {
        path: '/temporary',
        name: 'temporary',
        component: Temporary,
        meta: {
            title: '临时首页'
        }
    },
    {
        path: '/actorder',
        name: 'actorder',
        component: Actorder,
        meta: {
            title: '预开户激活',
            snsapiBase: true
        }
    },
    {
        path: '/actWebRtc',
        name: 'actWebRtc',
        component: ActWebRtc,
        meta: {
            title: '视频认证',
            //snsapiBase: true
        }
    },
    {
        path: '/actfront',
        name: 'actfront',
        component: Actfront,
        meta: {
            title: '身份证正面验证'
        }
    },
    {
        path: '/acttmallfront',
        name: 'acttmallfront',
        component: Acttmallfront,
        meta: {
            title: '身份证正面校验'
        }
    },
    // {
    //     path: '/ordercharge',
    //     name: 'ordercharge',
    //     component: Ordercharge,
    //     meta: {
    //         title: '稻米-订单支付'
    //     }
    // },
    //
    // {
    //     path: '/actricefront',
    //     name: 'actricefront',
    //     component: Actricefront,
    //     meta: {
    //         title: '稻米-身份证正面校验'
    //     }
    // },
    //  {
    //     path: '/actfrontwx',
    //     name: 'actfrontwx',
    //     component: Actfrontwx,
    //     meta: {
    //         title: '身份证正面校验'
    //     }
    // },


    {
        path: '/actback',
        name: 'actback',
        component: Actback,
        meta: {
            title: '身份证反面验证'
        }
    },
    {
        path: '/acttmallback',
        name: 'acttmallback',
        component: Acttmallback,
        meta: {
            title: '身份证反面校验'
        }
    },
    // {
    //     path: '/actriceback',
    //     name: 'actriceback',
    //     component: Actriceback,
    //     meta: {
    //         title: '稻米-身份证反面校验'
    //     }
    // },
    // {
    //     path: '/actbackwx',
    //     name: 'actbackwx',
    //     component: Actbackwx,
    //     meta: {
    //         title: '身份证反面验证'
    //     }
    // },
    {
        path: '/actvideo',
        name: 'actvideo',
        component: Actvideo,
        meta: {
            title: '视频认证'
        }
    },
    {
        path: '/acttmallvideo',
        name: 'acttmallvideo',
        component: Acttmallvideo,
        meta: {
            title: '视频验证'
        }
    },

    // {
    //     path: '/actricevideo',
    //     name: 'actricevideo',
    //     component: Actricevideo,
    //     meta: {
    //         title: '稻米-视频验证'
    //     }
    // },
    // {
    //     path: '/actvideowx',
    //     name: 'actvideowx',
    //     component: Actvideowx,
    //     meta: {
    //         title: '视频认证'
    //     }
    // },
	{
	    path: '/actComplete',
	    name: 'actComplete',
	    component: actComplete,
	    meta: {
	        title: '激活完成'
	    }
	},
    {
        path: '/charge/act',
        name: 'actcharge',
        component: Actcharge,
        meta: {
            title: '开户充值',
            snsapiBase: true
        }
    },
    // {
    //     path: '/charge/actwx',
    //     name: 'actchargewx',
    //     component: Actchargewx,
    //     meta: {
    //         title: '开户充值'
    //     }
    // },
     {
        path: '/charge/',
        name: 'charge',
        component: Charge,
        meta: {
            title: '话费充值',
            // snsapi_base:''
            snsapiBase: true
        }
    },
    {
        path: '/charge/test',
        name: 'chargetest',
        component: Chargetest,
        meta: {
            title: '话费充值',
            // snsapi_base:''
            // snsapiBase: true
        }
    },
    {
        path: '/rechargeorder/',
        name: 'rechargeorder',
        component: Rechargeorder,
        meta: {
            title: '充值记录',
            requireAuth: true

        }
    },
    {
        path: '/shop',
        name: 'shop',
        component: Shop,
        meta: {
            title: '入网须知'
        }
    },
    {
        path: '/baseinfo',
        name: 'baseinfo',
        component: Baseinfo,
        meta: {
            title: '我的基本信息',
            requireAuth: true

        }
    },
    {
        path: '/selectnum',
        name: 'selectnum',
        component: Selectnum,
        meta: {
            title: '选择号码'
        }
    },
    {
        path: '/selectprod',
        name: 'selectprod',
        component: Selectprod,
        meta: {
            title: '选择礼包'
        }
    },
    {
        path: '/address',
        name: 'address',
        component: Address,
        meta: {
            title: '填写地址'
        }
    },
    {
        path: '/bill',
        name: 'bill',
        component: Bill,
        meta: {
            title: '月账单',
            requireAuth: true
        }
    },
    // Pay history
    {
        path: '/prodused',
        name: 'prodused',
        component: Prodused,
        meta: {
            title: '我的套餐',
            // requireAuth: true
        }
    },
    {
        path: '/payhistory',
        name: 'payhistory',
        component: Payhistory,
        meta: {
            title: '充值记录',
            // requireAuth: true
        }
    },
    {
        path: '/detail',
        name: 'detail',
        component: Detail,
        meta: {
            title: '详单查询',
            // requireAuth: true
        }
    },
    {
        path: '/query',
        name: 'query',
        component: Query,
        meta: {
            title: '查询功能',
            // requireAuth: true
        }
    },
    {
        path: '/manage',
        name: 'manage',
        component: Manage,
        meta: {
            title: '业务办理',
            // requireAuth: true
        }
    },
    {
        path: '/voice',
        name: 'voice',
        component: Voice,
        meta: {
            title: '语音详单',
            // requireAuth: true
        }
    },
    {
        path: '/mess',
        name: 'mess',
        component: Mess,
        meta: {
            title: '短信详单',
            // requireAuth: true
        }
    },
    {
        path: '/web',
        name: 'web',
        component: Web,
        meta: {
            title: '流量详单',
            // requireAuth: true
        }
    },
    {
        path: '/remind',
        name: 'remind',
        component: Remind
    },
    {
        path: '/home',
        name: 'home',
        component: Home,
        meta: {
            title: '乐语通信',
            // requireAuth: true
        }
    },
    {
        path: '/fee',
        name: 'fee',
        component: Fee,
        meta: {
            title: '实时话费',
            requireAuth: true
        }
    },
    {
        path: '/login',
        name: 'login',
        component: Login,
        meta: {
            title: '服务密码登录',
            weixinAuth: true
        }
    },
    {
        path: '/reset',
        name: 'reset',
        component: Reset,
        meta: {
            title: '服务密码重置',
            // requireAuth: true,
            weixinAuth: true
        }
    },
    {
        path: '/codelogin',
        name: 'codelogin',
        component: Code,
        meta: {
            title: '验证码登录',
            // requireAuth: true,
            weixinAuth: true
        }
    },
    // forget
    {
        path: '/forget',
        name: 'forget',
        component: Forget,
        meta: {
            title: '忘记密码',
            // requireAuth: true
        }
    },
    {
        path: '/user',
        name: 'user',
        component: User,
        meta: {
            title: '会员中心',
            requireAuth: true
        }
    },
    {
        path: '/cart',
        name: 'cart',
        component: Cart,
        meta: {
            title: '购物车'
        }
    },
    {
        path: '/goods',
        name: 'goods',
        component: Goods,
        meta: {
            title: '商品详情'
        }
    },
    {
        path: '/about',
        name: 'about',
        component: About,
        meta: {
            title: 'about',
            requireAuth: true,
        }
    },{
        path: '/checkCard/',
        name: 'checkCard',
        component: checkCard,
        meta: {
            title: '乐语通信',
            // snsapi_base:''
            // snsapiBase: true
        }
    },{
        path: '/activate/batch',
        name: 'batchActivate',
        component: batchActivate,
        meta: {
            title: '用户激活'
        }
    },{
        path: '/activate/foreigner',
        name: 'foreignerActivate',
        component: foreignerActivate,
        meta: {
            title: '外国人永居证用户激活'
        }
    },{
        path: '/realname/index',
        name: 'realnameIndex',
        component: realnameIndex,
        meta: {
            title: '实名认证'
        }
    },{
        path: '/realname/index2',
        name: 'realnameIndex2',
        component: realnameIndex2,
        meta: {
            title: '二次实名'
        }
    },{
        path: '/realname/index3',
        name: 'realnameIndex3',
        component: realnameIndex3,
        meta: {
            title: '号码迁转确认'
        }
    },{
        path: '/rep/my-service-nums',
        name: 'repMyServiceNums',
        component: repMyServiceNums,
        meta: {
            title: '名下号码查询'
        }
    },{
        path: '/faq/index',
        name: 'faqIndex',
        component: faqIndex,
        meta: {
            title: '常见问题'
        }
    },{
        path: '/faq/protocol',
        name: 'faqProtocol',
        component: faqProtocol,
        meta: {
            title: '乐语通信入网协议'
        }
    },{
        path: '/faq/protocol2023',
        name: 'faqProtocol2023',
        component: faqProtocol2023,
        meta: {
            title: '乐语通信入网协议确认'
        }
    },{
        path: '/userOff/index',
        name: 'userOffIndex',
        component: userOffIndex,
        meta: {
            title: '号码销户'
        }
    },{
        path: '/refund/check',
        name: 'refundCheck',
        component: refundCheck,
        meta: {
            title: '退费确认'
        }
    },{
        path: '/wishes/sms',
        name: 'wishesSms',
        component: wishesSms,
        meta: {
            title: '短信免打扰',
            requireAuth: true
        }
    },{
        path: '/verify/netChannel',
        name: 'netChannel',
        component: netChannelVerify,
        meta: {
            title: '网渠核验'
        }
    },
	
    // {
    //     path: '/club',
    //     name: 'club',
    //     component: Club,
    //     meta: {
    //         title: '会员权益购买',
    //         requireAuth: true
    //     }
    // },
    // {
    //     path: '/ricenum',
    //     name: 'ricenum',
    //     component: Ricenum,
    //     meta: {
    //         title: '选择号码'
    //     }
    // },
    // {
    //     path: '/riceprod',
    //     name: 'riceprod',
    //     component: Riceprod,
    //     meta: {
    //         title: '选择套餐'
    //     }
    // },
    // {
    //     path: '/register',
    //     name: 'register',
    //     component: Register,
    //     meta: {
    //         title: '领卡登记',
    //         snsapiBase: true
    //     }
    // },
    // {
    //     path: '/ricecard',
    //     name: 'ricecard',
    //     component: Ricecard,
    //     meta: {
    //         title: '验证稻米卡'
    //     }
    // },
    // {
    //     path: '/custcharge',
    //     name: 'custcharge',
    //     component: Custcharge,
    //     meta: {
    //         title: '话费预存'
    //     }
    // },
];

// add route path
routes.forEach(route => {
    route.path = route.path || '/' + (route.name || '');
});

const router = new Router({routes});

router.beforeEach(async (to, from, next) => {
    const title = to.meta && to.meta.title;
    let token_cookie = getCookie('token')
    if (title) {
        document.title = title;
    }
    // 使用微信授权登录的方式  该步骤暂时隐去
    if (to.matched.some(r => r.meta.requireAuth)) {
        if (token_cookie) {
            next();
        } else {
            next({
                path: '/codelogin',
                query: {redirect: to.fullPath}
                // 将跳转的路由path作为参数，登录成功后跳转到该路由
            })
        }
    } else {
        next();
    }
    // 微信授权登录
    if (to.matched.some(recode => recode.meta.weixinAuth)) {
        console.log('wxuser===')
        let   wxuserinfo =  store.state.login.wxuserinfo
        console.log(wxuserinfo)
        // this.$store.state.login.wxuserinfo
        if(Object.keys(wxuserinfo).length !== 0){
            next()
            return
        }
        // if(Object.keys(localStorage_vuex).length !== 0){
        //     if (localStorage_vuex.root.login.nickname) {
        //         console.log('获取到了localStorage_vuex')
        //         store.commit('login/setUserInfo', localStorage_vuex.login.wxuserinfo)
        //         next()
        //         return
        //     }
        // }
        // if(localStorage_weixin){
        //     if (localStorage_weixin.nickname) {
        //         console.log('获取到了localStorage_weixin')
        //         store.commit('login/setUserInfo', localStorage_weixin)
        //         next()
        //         return
        //     }
        // }
        const code = getUrlParams('code') // 截取url上的code ,可能没有,则返回''空字符串
        if (typeof code == 'undefined') {
            console.log(window.location.origin)
            console.log(to.fullPath)
            // 这个redirectUrl用 当前页路径或者tof.fullPath(将要进入的路径)
            // let redirectUrl = window.location.href
            let redirectUrl = window.location.origin + "/#" + to.fullPath
            redirectUrl = encodeURIComponent(redirectUrl)
            console.log(redirectUrl)
            const appid = 'wxf0b469c2196fb55b'//10030
            window.location.href = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=${appid}&redirect_uri=${redirectUrl}&response_type=code&scope=snsapi_userinfo&state=123#wechat_redirect`
        }
        else {
            console.log('code:  '+code)
            let res  =  await  new Promise(function(resolve, reject){
                let openid = oauthbycode(code)
                resolve(openid)
            });

            // let res = oauthbycode(code) // 获取用户信息,后端可首先通过cookie,session等判断,没有信息则通过code获取
            console.log('oauthbycode-resres:::::')
            console.log(res)
            if (res.status === 200) {
                    if(res.data.code==0){
                        console.log('coming')
                        let  wxuser = {
                            openid:res.data.openid,
                            nickname:res.data.nickname,
                            headimgurl:res.data.headimgurl
                        }
                        // setCookie = ('wxuser',value,6404800)
                        // // this.$store.commit('productlist/getStoreListShow', data.page.list)
                        store.commit('login/setUserInfo', res.data)
                        // weixinStorage.save(res.data)
                        // console.log(res.data.nickname)
                        window.location.href  =  window.location.origin + "/#" + to.fullPath
                        // next()
                    }
                    else if(res.data.code==40163){
                        console.log(window.location.origin)
                        console.log(to.fullPath)
                        // 这个redirectUrl用 当前页路径或者tof.fullPath(将要进入的路径)
                        let redirectUrl = window.location.origin + "/#" + to.fullPath
                        redirectUrl = encodeURIComponent(redirectUrl)
                        console.log(redirectUrl)
                        const appid = 'wxf0b469c2196fb55b'//10030
                        window.location.href = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=${appid}&redirect_uri=${redirectUrl}&response_type=code&scope=snsapi_userinfo&state=123#wechat_redirect`
                    }
            } else {
                // 上面的获取用户信息接口,如果cookie,session拿不到用户信息,且传递的code为空,则跳转到微信授权页面
                if (res.status === 202) {
                    console.log(window.location.origin)
                    console.log(to.fullPath)
                    // 这个redirectUrl用 当前页路径或者tof.fullPath(将要进入的路径)
                    let redirectUrl = window.location.origin + "/#" + to.fullPath
                    redirectUrl = encodeURIComponent(redirectUrl)
                    console.log(redirectUrl)
                    const appid = 'wxf0b469c2196fb55b'//10030
                    window.location.href = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=${appid}&redirect_uri=${redirectUrl}&response_type=code&scope=snsapi_userinfo&state=123#wechat_redirect`
                }
            }

        }
        // 返回用户信息
        // {"openid":"o5Tqx0eAhGBW2einiqTyxaqT-l7g","nickname":"许仙","sex":1,"language":"zh_CN","city":"杭州","province":"浙江","country":"中国","headimgurl":"http://thirdwx.qlogo.cn/mmopen/vi_32/ibhtj6joaSEaaR3wplCGwPHrHA0m4ZBicWcAgzE0qqm12s4icVSdRwKGzefIrvsmVhM0bDQ1f1ibGGkzyoic8Gx1FYw/132","privilege":[]}
    } else {
        next()
    }
   // 微信 静默授权  snsapiBase    {msg: "success", code: 0, openid: "oanVsuOLU5sfaNVldrIz91xlzWLE", nickname: "许仙", headimgurl: "http://thirdwx.qlogo.cn/mmopen/vi_32/uKo8NYAN7FQOO…UkAART7xoJbdKLXG4dIBS9myYPErb5TRqgRicBpKqiafw/132"}
    if (to.matched.some(recode => recode.meta.snsapiBase)) {
        console.log('openid===')
        console.log(getCookie('openid'))
        if (getCookie('openid')) {
            next()
            return
        }
        // if(Object.keys(localStorage_vuex).length !== 0){
        //     if (localStorage_vuex.root.login.nickname) {
        //         console.log('获取到了localStorage_vuex')
        //         store.commit('login/setUserInfo', localStorage_vuex.login.wxuserinfo)
        //         next()
        //         return
        //     }
        // }
        // if(localStorage_weixin){
        //     if (localStorage_weixin.nickname) {
        //         console.log('获取到了localStorage_weixin')
        //         store.commit('login/setUserInfo', localStorage_weixin)
        //         next()
        //         return
        //     }
        // }
        const code = getUrlParams('code') // 截取url上的code ,可能没有,则返回''空字符串
        if (typeof code == 'undefined') {
            console.log(window.location.origin)
            console.log(to.fullPath)
            // 这个redirectUrl用 当前页路径或者tof.fullPath(将要进入的路径)
            // let redirectUrl = window.location.href
            let redirectUrl = window.location.origin + "/#" + to.fullPath
            redirectUrl = encodeURIComponent(redirectUrl)
            console.log(redirectUrl)
            const appid = 'wxf0b469c2196fb55b'//10030
            window.location.href = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=${appid}&redirect_uri=${redirectUrl}&response_type=code&scope=snsapi_userinfo&state=123#wechat_redirect`
        }
        else {
            console.log('code:  '+code)
            // alert('code:  '+code)
            let res  =  await  new Promise(function(resolve, reject){
                let openid = oauthbycode(code)
                // alert(openid)
                resolve(openid)
            });

            // let res = oauthbycode(code) // 获取用户信息,后端可首先通过cookie,session等判断,没有信息则通过code获取
            console.log('oauthbycode-resres:::::')
            console.log(res)
            if (res.status === 200) {
                if(res.data.code==0){
                    console.log('coming')
                    // let  wxuser = {
                    //     openid:res.data.openid
                    //     // nickname:res.data.nickname,
                    //     // headimgurl:res.data.headimgurl
                    // }
                    // alert(JSON.stringify(res.data))
                    // Toast.success(JSON.stringify(res.data))

                    let   openid =   res.data.openid
                    setCookie('openid',openid,6404800)
                    // // this.$store.commit('productlist/getStoreListShow', data.page.list)
                    // store.commit('login/setUserInfo', res.data)
                    // weixinStorage.save(res.data)
                    // console.log(res.data.nickname)
                    window.location.href  =  window.location.origin + "/#" + to.fullPath

                    // next()
                }
                else if(res.data.code==40163){
                    console.log(window.location.origin)
                    console.log(to.fullPath)
                    // 这个redirectUrl用 当前页路径或者tof.fullPath(将要进入的路径)
                    let redirectUrl = window.location.origin + "/#" + to.fullPath
                    redirectUrl = encodeURIComponent(redirectUrl)
                    console.log(redirectUrl)
                    const appid = 'wxf0b469c2196fb55b'//10030
                    window.location.href = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=${appid}&redirect_uri=${redirectUrl}&response_type=code&scope=snsapi_userinfo&state=123#wechat_redirect`
                }
            } else {
                // 上面的获取用户信息接口,如果cookie,session拿不到用户信息,且传递的code为空,则跳转到微信授权页面
                if (res.status === 202) {
                    console.log(window.location.origin)
                    console.log(to.fullPath)
                    // 这个redirectUrl用 当前页路径或者tof.fullPath(将要进入的路径)
                    let redirectUrl = window.location.origin + "/#" + to.fullPath
                    redirectUrl = encodeURIComponent(redirectUrl)
                    console.log(redirectUrl)
                    const appid = 'wxf0b469c2196fb55b'//10030
                    window.location.href = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=${appid}&redirect_uri=${redirectUrl}&response_type=code&scope=snsapi_userinfo&state=123#wechat_redirect`
                }
            }

        }
        // 返回用户信息
        // {"openid":"o5Tqx0eAhGBW2einiqTyxaqT-l7g","nickname":"许仙","sex":1,"language":"zh_CN","city":"杭州","province":"浙江","country":"中国","headimgurl":"http://thirdwx.qlogo.cn/mmopen/vi_32/ibhtj6joaSEaaR3wplCGwPHrHA0m4ZBicWcAgzE0qqm12s4icVSdRwKGzefIrvsmVhM0bDQ1f1ibGGkzyoic8Gx1FYw/132","privilege":[]}
    } else {
        next()
    }
});

export {
    router
};
