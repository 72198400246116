// src/api/common.js
import axios from 'axios'
// import VueRouter from 'vue-router'
// import routes from '../router/router'
import { Toast } from 'vant';
import qs from 'qs';
import store from "@/store";

import { router } from '@/router/router';
// axios.defaults.baseURL = 'http://localhost:8080'
// axios 请求拦截
// const router = new VueRouter({
//   routes
// });

// const baseUrl  =  'http://192.168.52.12:8003/sun-wxt';
const baseUrl  =  '//boss.10028.cn/sun-wxt';//生产  最后不以"/"结尾，调用时会自带"/"，否则会出现"//"，waf会拦截（路径遍历攻击）
//const baseUrl  =  '//boss.funtalk.cn/test-sun-wxt';
//const baseUrl  =  'https://voice.ofish.cn/sun-wxt';
//const baseUrl  =  'http://localhost:9003/sun-wxt';
// console.log(router)
axios.interceptors.request.use((response)=>{
  // 在 headers 中设置authorization 属性放token，token是存在缓存中的
  // response.headers.authorization = getCookie('token')
  response.headers.token = getCookie('token')
  // response.headers.authorization = `Bearer `+ getCookie('token')
  // headers:{
  //   // 切记 token 不要直接发送，要在前面加上 Bearer 字符串和一个空格
  //   'Authorization':`Bearer ${token}`
  // }
  return response
},  (error) =>{
  return Promise.reject(error);
})
// axios 响应拦截器
// http response 拦截器
// axios 响应拦截器
// axios.interceptors.response.use(function (response) {
//   return response;
// }, function (error) {
//   return Promise.reject(error);
// });
  axios.interceptors.response.use(
    response => {
    console.log('axios ----response:::')
    console.log(response)
        if (response.status === 200) {
            return response;
        } else {
            return Promise.reject(response);
        }

      //   if(response.data.status===401){
      //   clearCookie('token')
      //   alert('登录超时，请重新登录')
      //   // 只有在当前路由不是登录页面才跳转
      //   console.log(router.currentRoute)
      //   console.log(router.currentRoute.path)
      //   // router.push("/");
      //   router.currentRoute.path !== 'login' &&
      //   router.replace({
      //     path: 'login',
      //     query: { redirect: router.currentRoute.path },
      //   })
      //   window.location.reload();//刷新
      // }
    // return response
  },
     error => {
    console.log('axios---error:::')
    console.log(error)
    console.log(error.response.status)
      switch (error.response.status) {
        case 401:
          // console.log('401')
          // 401 清除token信息并跳转到登录页面
          // store.commit(types.LOGOUT)
          clearCookie('token')
          // 只有在当前路由不是登录页面才跳转
          console.log(router.currentRoute)
          console.log(router.currentRoute.path)
          // router.push("/");
          router.currentRoute.path !== 'login' &&
          router.replace({
            path: 'login',
            query: { redirect: router.currentRoute.path },
          })
          window.location.reload();//刷新
          break;
          case 403:
              Toast({
                  message: '登录过期，请重新登录',
                  duration: 1000,
                  forbidClick: true
              });
              // 清除token
              clearCookie('token')
              // store.commit('loginSuccess', null);
              // 跳转登录页面，并将要浏览的页面fullPath传过去，登录成功后跳转需要访问的页面
              setTimeout(() => {
                  router.replace({
                      path: 'login',
                      query: {
                          redirect: router.currentRoute.fullPath
                      }
                  });
              }, 1000);
              break;
          case 404:
              Toast({
                  message: '网络请求不存在',
                  duration: 1500,
                  forbidClick: true
              });
              break;
          default:
          break;

      }

    // console.log(JSON.stringify(error));//console : Error: Request failed with status code 402
    // return Promise.reject(error.response.data)
    return Promise.reject(error.response)
  }
)

// 封装get
/**
 * get方法，对应get请求
 * @param {String} url [请求的url地址]
 * @param {Object} params [请求时携带的参数]
 */
export let axiosGet = (url, params)=>{
    url = baseUrl+ url
    // alert('get::::')
    // alert(url)
    return  new Promise((resolve, reject) =>{
        axios.get(url, {
            params: params
        }).then(res => {
                console.log(res)
                // alert('getjiekou')
                // alert(JSON.stringify(res))
                resolve(res);
            }).catch(err => {
                // alert('baocuo:::::')
                // alert(JSON.stringify(err))
                // alert(err)
                console.log(err)
                reject(err)
            })
    });
}

/**
 * post方法，对应post请求
 * @param {String} url [请求的url地址]
 * @param {Object} params [请求时携带的参数]
 */
export let axiosPost =(url,contentType = 'json', params)=> {
    console.log(contentType)
    // console.log(contentType = 'json'?)

    url = baseUrl+ url
    return new Promise((resolve, reject) => {
        params   =     contentType == 'json' ? JSON.stringify(params) : qs.stringify(params)
        console.log(params)
         let headers  =   contentType == 'json' ? {
             'Content-Type':'application/json; charset=utf-8'
         } : {
             'Content-Type':'application/x-www-form-urlencoded; charset=utf-8'
         }
        //let  headers = ''
        //if(contentType=='json'){
        //    headers  =   {
        //        'Content-Type':'application/json; charset=utf-8'
        //    }
        //}
        //else {
        //    headers  =  {
        //         'Content-Type':'application/x-www-form-urlencoded; charset=utf-8'
        //        // 'Content-Type': 'multipart/form-data'// ;boundary=----WebKitFormBoundaryQ6d2Qh69dv9wad2u
        //    }
        //}
        console.log(headers)
        axios.post(url, params,{headers:headers})
            .then(res => {
                resolve(res);
            })
            .catch(err => {
                reject(err)
            })
    });
}

/**
 * postFormData方法，对应post请求，用来提交文件+数据
 * @param {String} url [请求的url地址]
 * @param {Object} params [请求时携带的参数]
 */
export let axiosPostForm = (url, params) =>{
    url = baseUrl+ url
    return new Promise((resolve, reject) => {
        axios({
            headers: {
                'Content-Type': 'multipart/form-data'// ;boundary=----WebKitFormBoundaryQ6d2Qh69dv9wad2u
            },
            transformRequest: [function (data) { // 在请求之前对data传参进行格式转换
                const formData = new FormData()
                Object.keys(data).forEach(key => {
                    formData.append(key, data[key])
                })
                return formData
            }],
            url,
            method: 'post',
            data: params
        }).then(res => {
            resolve(res.data)
        }).catch(err => {
            reject(err)
        })
    })
}

export let getUUID =  () => {
    let  uuidSms = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, c => {
        return (c === 'x' ? (Math.random() * 16 | 0) : ('r&0x3' | '0x8')).toString(16)
    })
    store.commit('login/setuuidSms', uuidSms)
    return   uuidSms

}
// 获取微信粉丝信息
export let oauthbycode = (code) => {
    // return axios.post('/login',{user,password})
    console.log('程序：传递code给后台获取微信粉丝信息：：：')
    const interfaceUrl  =  '/app/wxauth/getWxAuth'
    // let params = new URLSearchParams();
    // params.append('code', code);
    let t  = new Date().getTime()
    // params.append('t', t);
    let params= {
        code: code,
        t:t
    }
    console.log(params)
    // alert('code')
    // alert(JSON.stringify(params))

    let wx_res =  axiosGet(interfaceUrl,params)
    // console.log('wx_res:::::')
    // console.log(wx_res)
    // alert('oauthbycode-res')
    // alert(JSON.stringify(wx_res))

    return wx_res

}
// oauthBase
export let oauthBase = (code) => {
    // return axios.post('/login',{user,password})
    console.log('程序：传递code给后台获取微信粉丝信息：：：')
    const interfaceUrl  =  '/app/wxauth/getOpenid'
    // let params = new URLSearchParams();
    // params.append('code', code);
    let t  = new Date().getTime()
    // params.append('t', t);
    let params= {
        code: code,
        t:t
    }
    console.log(params)
    let wx_res =  axiosGet(interfaceUrl,params)
    // console.log('wx_res:::::')
    // console.log(wx_res)
    return wx_res

}
// 验证码登录接口
export let codeloginApi = (smsLoginurl,type,params_smsLogin) => {
  // return axios.post('/login',{user,password})
  console.log('code---loginApi执行：')
    let    smsLogin  = axiosPost(smsLoginurl,type,params_smsLogin)
    console.log(smsLogin)
    smsLogin.then(
        function(data){
            // console.log('resolved成功回调');
            console.log('登录成功：',data);
            if(data.data.code === 0){
                setCookie('token',data.data.token,data.data.expire)
                Toast.success('登录成功');

                // return  {
                //     "msg": "success",
                //     "code": 0
                // }
            }
            else {

            }
        }
    )
        .catch(function(reason, data){
            Toast.fail('登录失败');
            // return  {
            //     "msg": "fail",
            //     "code": 500
            // }
        });
  return smsLogin

}
// 登录的接口
export let loginApi = (userName,userPwd) => {
    // return axios.post('/login',{user,password})
    console.log('loginApi执行：')
    let loginApi_res =   axios.post('/admin/login',{userName,userPwd})
    console.log(loginApi_res)
    return loginApi_res

}
// 登出的接口
export let loginOut = () => {
    clearCookie('token')
    // openidStorage
    vuexStorage.save({})
    activateStorage.save({})
    // openidStorage
    stepStorage.save({})
    // return loginApi_res

}

// 验证是否登录的接口
export let valiApi = () => {
  let  valiApi  =   axios.post('/admin')
  console.log('valiApi：')
  console.log(valiApi)
  return valiApi
}



// // 登chu的接口
// export let loginoutApi = () => {
//
// }

// 设置cookie
export let setCookie = (name,value,expire)=> {
  // var Days = 1;
  // var exp = new Date();
  // exp.setTime(exp.getTime() + Days*30*1000);
  document.cookie = name + "="+ escape (value) + ";expires=" + expire;
}

//读取cookies
export let getCookie=(name) =>{
  var arr,reg=new RegExp("(^| )"+name+"=([^;]*)(;|$)");
  if(arr=document.cookie.match(reg))
    return unescape(arr[2]);
  else
    return null;
}
// 清除cookie
export let clearCookie = (name) =>{
  setCookie(name, "", -1);
}
export let  getUrlParams = (name)=> { // 不传name返回所有值，否则返回对应值
  var url = window.location.search;
  if (url.indexOf('?') == 1) { return false; }
  url = url.substr(1);
  url = url.split('&');
  var name = name || '';
  var nameres;
  // 获取全部参数及其值
  for(var i=0;i<url.length;i++) {
    var info = url[i].split('=');
    var obj = {};
    obj[info[0]] = decodeURI(info[1]);
    url[i] = obj;
  }
  // 如果传入一个参数名称，就匹配其值
  if (name) {
    for(var i=0;i<url.length;i++) {
      for (const key in url[i]) {
        if (key == name) {
          nameres = url[i][key];
        }
      }
    }
  } else {
    nameres = url;
  }
  // 返回结果
  return nameres;
}
   // 未完待续

const STORAGE_KEY = 'weixin';
const STORAGE_vuex = 'vuex';
const STORAGE_openid = 'openid';
const STORAGE_activate = 'activate';
const STORAGE_shop= 'shop';
const STORAGE_step = 'actstep';
export let weixinStorage = {
    fetch: function () {
        return JSON.parse(localStorage.getItem(STORAGE_KEY));
    },
    save: function (Mess) {
        localStorage.setItem(STORAGE_KEY, JSON.stringify(Mess));
    }
};
export let vuexStorage = {
    fetch: function () {
        return JSON.parse(localStorage.getItem(STORAGE_vuex));
    },
    save: function (Mess) {
        localStorage.setItem(STORAGE_vuex, JSON.stringify(Mess));
    }
};
export let activateStorage = {
    fetch: function () {
        return JSON.parse(sessionStorage.getItem(STORAGE_activate));
    },
    save: function (Mess) {
        sessionStorage.setItem(STORAGE_activate, JSON.stringify(Mess));
    }
};
export let openidStorage = {
    fetch: function () {
        return JSON.parse(localStorage.getItem(STORAGE_openid));
    },
    save: function (Mess) {
        localStorage.setItem(STORAGE_openid, JSON.stringify(Mess));
    }
};

export let stepStorage = {
    fetch: function () {
        return JSON.parse(sessionStorage.getItem(STORAGE_step));
    },
    save: function (Mess) {
        sessionStorage.setItem(STORAGE_step, JSON.stringify(Mess));
    }
};

export let shopStorage = {
    fetch: function () {
        return JSON.parse(sessionStorage.getItem(STORAGE_shop));
    },
    save: function (Mess) {
        sessionStorage.setItem(STORAGE_shop, JSON.stringify(Mess));
    }
};

export let fetch = (name) =>{
        return JSON.parse(sessionStorage.getItem(name));
};
export let save = (name,mess) =>{
    sessionStorage.setItem(name, JSON.stringify(mess));
};


export let localFetch = (name) =>{
    return JSON.parse(localStorage.getItem(name));
};
export let localSave = (name,mess) =>{
    localStorage.setItem(name, JSON.stringify(mess));
};

export let formatTimeDate = (date,format) =>{
	date = new Date(date);
	const year = date.getFullYear()
	const month = date.getMonth() + 1
	const day = date.getDate()
	const hour = date.getHours()
	const minute = date.getMinutes()
	const second = date.getSeconds()
	if(format==1){
		return [year, month, day].map(formatNumber).join('-') + ' ' + [hour, minute, second].map(formatNumber).join(':')
	}
	if(format==2){
		return [year, month, day];
	}
	return [year, month, day].map(formatNumber).join('-');
}

const formatNumber = n => {
    n = n.toString()
    return n[1] ? n : '0' + n
}