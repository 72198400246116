import Vue from 'vue';
import App from './App';
import { router } from '@/router/router';
import axios from 'axios'
import async from 'async'
import store from "@/store";
import qs from 'qs';
import {axiosGet, axiosPost, axiosPostForm} from '@/api/login'
import wx from 'weixin-js-sdk';
import md5 from 'js-md5'
//axios超时重试
axios.defaults.retry = 4;
axios.defaults.timeout =  60000;
axios.defaults.retryDelay = 1000;
axios.interceptors.response.use(undefined, function axiosRetryInterceptor(err) {
  var config = err.config;
  // If config does not exist or the retry option is not set, reject
  if(!config || !config.retry) return Promise.reject(err);

  // Set the variable for keeping track of the retry count
  config.__retryCount = config.__retryCount || 0;

  // Check if we've maxed out the total number of retries
  if(config.__retryCount >= config.retry) {
    // Reject with the error
    return Promise.reject(err);
  }

  // Increase the retry count
  config.__retryCount += 1;

  // Create new promise to handle exponential backoff
  var backoff = new Promise(function(resolve) {
    setTimeout(function() {
      resolve();
    }, config.retryDelay || 1);
  });

  // Return the promise in which recalls axios to retry the request
  return backoff.then(function() {
    return axios(config);
  });
});
Vue.prototype.$md5 = md5;
Vue.prototype.$axios = axios;
Vue.prototype.$qs = qs;
Vue.prototype.$axiosGet = axiosGet // ajax请求方法   axios
Vue.prototype.$axiosPost = axiosPost // ajax请求方法   axios
Vue.prototype.$axiosPostForm = axiosPostForm // ajax请求方法   axios
Vue.prototype.$wx = wx // ajax请求方法   axios
Vue.prototype.$async = async // ajax请求方法   axios

// 路由拦截   暂时没有开启
//
// router.beforeEach((to, from, next) => {
//   let   token_cookie  = getCookie('token')
//   if (to.matched.some(r => r.meta.requireAuth)) {
//     if (token_cookie) {
//       next();
//     }
//     else {
//       next({
//         path: '/login',
//         query: {redirect: to.fullPath}
//         // 将跳转的路由path作为参数，登录成功后跳转到该路由
//       })
//     }
//   }
//   else {
//     next();
//   }
// })
import {
  Tag,
  Col,
  Icon,
  Cell,
  CellGroup,
  Swipe,
  Toast,
  SwipeItem,
  GoodsAction,
  GoodsActionIcon,
  GoodsActionButton,
  Form,
  Button ,
  Grid,
  GridItem,
  TabbarItem,
  Tabbar,
  Tab,
  Tabs,
  Row,
  Pagination,
  Radio,
  RadioGroup,
  Picker,
  NoticeBar,
  Popup,
  Field,
  Dialog
} from 'vant';
Vue.use(Tag);
Vue.use(Col);
Vue.use(Icon);
Vue.use(Cell);
Vue.use(CellGroup);
Vue.use(Swipe);
Vue.use(Toast);
Vue.use(SwipeItem);
Vue.use(GoodsAction);
Vue.use(GoodsActionIcon);
Vue.use(GoodsActionButton);
Vue.use(Button);
Vue.use(Form);
Vue.use(Field);
Vue.use(Grid);
Vue.use(GridItem);
Vue.use(Tabbar);
Vue.use(TabbarItem);
Vue.use(Tab);
Vue.use(Tabs);
Vue.use(Row);
Vue.use(Pagination);
Vue.use(Radio);
Vue.use(RadioGroup);
Vue.use(Popup);
Vue.use(Picker);
Vue.use(NoticeBar);
import { Image as VanImage } from 'vant';

Vue.use(VanImage);
// import Vue from 'vue';
import { Circle } from 'vant';
import { NumberKeyboard } from 'vant';
import { Uploader } from 'vant';

Vue.use(Uploader);
Vue.use(NumberKeyboard);
import { SubmitBar } from 'vant';

Vue.use(SubmitBar);
Vue.prototype.$Toast = Toast
// import Vue from 'vue';
import { Step, Steps } from 'vant';
Vue.use(Step);
Vue.use(Steps);
import { Lazyload } from 'vant';

Vue.use(Lazyload);
import { PullRefresh } from 'vant';

Vue.use(PullRefresh);
import { List } from 'vant';

Vue.use(List);
// 全局注册
Vue.use(Dialog);
Vue.prototype.$Dialog = Dialog // ajax请求方法   axios

import { Overlay } from 'vant';

Vue.use(Overlay);
import { Loading } from 'vant';

Vue.use(Loading);
import { Collapse, CollapseItem } from 'vant';

Vue.use(Collapse);
Vue.use(CollapseItem);
import { Checkbox, CheckboxGroup } from 'vant';

Vue.use(Checkbox);
Vue.use(CheckboxGroup);
import { AddressEdit } from 'vant';

Vue.use(AddressEdit);
import { Search } from 'vant';

Vue.use(Search);


import { Area } from 'vant';

Vue.use(Area);

import { Calendar } from 'vant';
Vue.use(Calendar);

import "./assets/icon/shouye/iconfont.css";
import "./assets/icon/smswishes/iconfont.css";
new Vue({
  router,
  store,
  el: '#app',
  render: h => h(App)
});
